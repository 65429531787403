.blog-list-wrapper {
  margin: 0;
  padding: 2rem 25%;
  padding-top: 10rem;
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .blog-list-wrapper {
    padding: 1rem 5%;
    padding-top: 6rem;
  }
}

.title {
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;
}

.blog-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.blog-preview {
  border: 1px solid gray;
  padding: 1rem;
  border-radius: 10px;
  background-color: #222;
  opacity: 0.6;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.blog-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.blog-preview:hover {
  opacity: 1;
}

.blog-preview-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}

.blog-preview-content {
  flex: 1;
}

.blog-link h3 {
  margin: 0;
}

.blog-link .blog-date {
  font-style: italic;
  margin: 0.5rem 0 0;
}

.blog-link p {
  margin: 0.5rem 0 0;
}
