.home-header {
    position: relative;
    width: 100%;
    height: 70vh; /* Adjust to cover 50% of the screen height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: black; 
  }
  
  #particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .header-content {
    position: absolute; /* Change from relative to absolute */
    text-align: center;
    color: white;
    padding: 2rem;
    z-index: 1;
  }
  
  .main-title {
    font-size: 2.5rem; /* Adjust the font size as needed */
    margin: 0;
  }
  
  .subtitle {
    font-size: 1.5rem; /* Adjust the font size as needed */
    margin: 1rem 0 0 0;
    color: var(--orange);
  }
  